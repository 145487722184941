@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    font-family: 'Poppins', sans-serif;
  }
}

.paddingClass {
  @apply px-2 lg:px-20;
}
.b {
  @apply font-extrabold;
}
.container {
  display: inline-block;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

.change .bar1 {
  transform: translate(0, 11px) rotate(-45deg);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: translate(0, -11px) rotate(45deg);
}
.fade-in-image {
  transition: 3s ease-out;
}
.homeBackGround {
  background-image: url('/assets/images/home-bg-bm.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  background-position: center;

  /* background-attachment: sticky; */
}
::-webkit-scrollbar {
  display: none;
}

.react-calendar {
  background: #212529;
  color: white;
  border: none;
  font-size: 16px;
  padding: 20px 15px;
  width: 100%;
  max-width: 100%;
  width: 100%;
  height: 450px;
}
.react-calendar__navigation__label__labelText {
  padding: 20px;
}
.react-calendar__navigation {
  padding: 10px;
  text-align: center;
}
.react-calendar__navigation__arrow {
  padding: 10px;
  font-size: 30px;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 20px;
}

.react-calendar__tile abbr:hover {
  background: #f3f3f3;
  color: #212529;
}
.react-calendar__tile {
  width: 45px;
  height: 45px;
  padding: 5px;
}
.react-calendar__tile abbr {
  padding: 12px 15px;
  border-radius: 50px;
}

.react-calendar__tile--active abbr {
  background: white;
  color: #212529;
}

.react-calendar__tile:disabled abbr {
  background: transparent;
  color: #5e5e5e;
}
